import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Blogs from './components/Blogs';

function App() {
  return (
    <section className='container'>
      <Header />
      <Routes>
        <Route path='/' element={<Hero />} />
        <Route path='/blogs' element={<Blogs />} />
      </Routes>
      <Footer />
    </section>
  );
}

export default App;
