import React from 'react';
import { blogs } from "../../lib/constants";
import BlogIntro from './BlogIntro';

export default function Blogs() {
    return (
        <section className="mb-2">
            {blogs.map(e => <BlogIntro key={e.date} post={e} />)}
        </section>
    );
};
