import React from "react";
import { Links, openLink } from "../../lib/utility";

export default function Footer() {
    return (
        <footer>
            <hr />
            <div>
                {Links.All.map(e => (
                    <React.Fragment key={'f' + e}>|<span key={e} className="social-links" onClick={() => openLink(e)}> {e} </span>| </React.Fragment>
                ))}
            </div>
        </footer>
    );
};