import { Link, useLocation } from 'react-router-dom';

export default function Header() {
    const { pathname } = useLocation();

    return (
        <header className='header'>
            <h1 className='desktop'>deepanshujain</h1>
            <h1 className='mobile'>dj</h1>
            <h1>
                <Link to={"/"}>
                    <span>
                        &#127968;
                        <span className={pathname === "/" ? "page-active" : ""}>home</span>
                    </span>
                </Link>|
                <Link to={"/blogs"}>
                    <span>
                        &#128213;
                        <span className={pathname.includes("blogs") ? 'page-active' : ""}>blogs</span>
                    </span>
                </Link>
            </h1>
        </header>
    );
};