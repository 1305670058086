export namespace Links {
    export enum Social{
        Github = "github",
        Email = "email",
        LinkedIn = "linkedin",
        Resume = "resume"
    };
    
    export const All = [
        Links.Social.LinkedIn,
        Links.Social.Github,
        Links.Social.Resume
    ];
}

export const openLink = (type: Links.Social) => {
    let url = "";
    switch (type) {
        case Links.Social.Github: url = "https://github.com/deeepanshu";
            break;
        case Links.Social.LinkedIn: url = "https://www.linkedin.com/in/deeepanshu98/";
            break;
        case Links.Social.Email: url = "mailto:deepanshujain1234@live.com";
            break;
        case Links.Social.Resume: url = "https://drive.google.com/file/d/19C33ds5K8QY5k1iqtv8VJgKFent_mSpB/view?usp=sharing";
            break;
        default: url = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
    }
    window.open(url, "_blank");
}