import { useEffect, useRef, useState } from "react";

const salutations = ["नमस्ते",  "hi", "hola", "bonjour", "konnichiwa", "guten tag"];

export default function Hero() {
    const timer = useRef<NodeJS.Timer | null>(null);
    const [tick, setTick] = useState(0);

    useEffect(() => {
        timer.current = setInterval(() => setTick(t => t + 1), 1000);
        return () => {
            timer.current && clearInterval(timer.current);
        };
    }, []);

    return (
        <section>
            <p className="text-center desktop">{salutations[tick % salutations.length]}! I am Deepanshu Jain! I am a Software Engineer @ Agoda.</p>
            <p className="text-center mobile">{salutations[tick % salutations.length]}! I am Deepanshu Jain!<br /> I am a Software Engineer @ Agoda.</p>

            <h2 className="text-center">&#128583; little about me</h2>
            <p>
                I am a Full Stack Engineer with 3+ years of experience.
                I work on Javascript based frameworks and libraries such as NodeJs, ReactJs.
                I am learning .NET these days and also learning more about System Designing.
            </p>
            <p>
                Currently I am learning about a lot of new things, such as playing guitar and writing.
                You can find my technical blogs in the blogs section.
            </p>
        </section>
    );
};