import React from 'react';
import { blogs } from '../../lib/constants';

interface BlogIntroProps {
    post: typeof blogs[0];
}

export default function BlogIntro({ post }: BlogIntroProps) {
    const { title, excerpt, author, externalUrl, date} = post;
    return (
        <section className={'post-intro-box'}>
            <a href={externalUrl} target="_blank" rel="noreferrer">
                <div>
                    <h2>{title}</h2>
                    <p>{excerpt}</p>
                    <div className={'post-meta-data'}>
                        <span>{date}</span>
                        <span>- {author.name}</span>
                    </div>
                </div>
            </a>
        </section>
    );
};