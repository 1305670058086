export const blogs = [
    {
        title: 'What is Docker',
        excerpt: 'Docker is an open platform written in GoLang which helps us in shipping and maintaining applications effectively and efficiently. You can build, test, and deploy your application in no time.',
        coverImage: '/assets/blog/hello-world/cover.jpg',
        date: '2022-05-29',
        author: {
            name: 'Deepanshu Jain',
            picture: '/assets/blog/authors/tim.jpeg'
        },
        ogImage: {
            url: '/assets/blog/hello-world/cover.jpg'
        },
        externalUrl: 'https://medium.com/@deeepanshu/whats-docker-6f258d39274d'
    }
]